import { DefaultProps } from '../interface';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import './insurance.scss';
import { FunctionComponent } from 'react';

const cover = [
  {
    name: '3th',
    src: '/assets/pages/help/insurance/third-person-icon.svg',
    element_th: (
      <>
        ความคุ้มครอง<strong>บุคคลที่ 3</strong>
      </>
    ),
    element_en: <>Third Party Liability</>,
    short_element: <>บุคคลที่ 3</>
  },
  {
    name: 'zero',
    src: '/assets/pages/help/insurance/car-frame.svg',
    element_th: (
      <>
        ความคุ้มครอง<strong>Zero Excess (ตัวถัง)</strong>
      </>
    ),
    element_en: 'Zero Excess Coverage (Body)',
    short_element: <>Zero Excess (ตัวถัง)</>
  },
  {
    name: 'thief',
    src: '/assets/pages/help/insurance/theft-icon.svg',
    element_th: (
      <>
        ความคุ้มครอง<strong>โจรกรรม</strong>
      </>
    ),
    element_en: 'Theft Protection Coverage',
    short_element: <>โจรกรรม</>
  },
  {
    name: 'wheel',
    src: '/assets/pages/help/insurance/window-tyre-icon.svg',
    element_th: (
      <>
        ความคุ้มครอง<strong>กระจก ยาง และล้อ </strong>
      </>
    ),
    element_en: 'Glass, Tire, and Wheel Coverage',
    short_element: <>กระจก ยาง และล้อ</>
  }
];
const tableHeader = {
  th: {
    title: 'ประเภทประกันภัย',
    child: [
      {
        name: '',
        src: '',
        element_th: <strong>ราคาต่อวัน*</strong>,
        element_en: <strong>Price per Day*</strong>,
        short_element: <div />
      }
    ]
  },
  en: {
    title: 'Types of Insurance Coverage',
    child: [
      {
        name: '',
        src: '',
        element_th: <strong>ราคาต่อวัน*</strong>,
        element_en: <strong>Price per Day*</strong>,
        short_element: <div />
      }
    ]
  }
};

const coverType = ['3th', 'zero', 'thief', 'wheel'];
type headerType = {
  title: string;
  child: {
    name: string;
    src: string;
    element_th: JSX.Element;
    element_en: JSX.Element;
    short_element: JSX.Element;
  }[];
};

type insuranceType = {
  insurance: string;
  insurance_short: string;
  cover_type: string[];
  price: string;
};

const insurances = {
  th: [
    {
      insurance: 'ประกันพื้นฐาน มีค่าเสียหายส่วนแรก (CDW)',
      insurance_short: 'CDW',
      cover_type: ['3th'],
      price: 'รวมในค่าเช่าแล้ว'
    },
    {
      insurance: 'ประกันแบบไม่มีค่าเสียหายส่วนแรก Zero Excess (SCDW/SLDW/ECDW)',
      insurance_short: 'SCDW/SLDW/ECDW',
      cover_type: ['3th', 'zero'],
      price: '240-535 บาท'
    },
    {
      insurance: 'ประกันแบบไม่มีค่าเสียหายส่วนแรกเต็มรูปแบบ (PCDW/PLDW)',
      insurance_short: 'PCDW/PLDW',
      cover_type: ['3th', 'zero', 'wheel'],
      price: '350-550 บาท'
    },
    {
      insurance: 'ประกันการโจรกรรม (TP) ',
      insurance_short: 'TP',
      cover_type: ['thief'],
      price: '85-150 บาท'
    },
    {
      insurance: 'ประกันแบบไม่มีค่าเสียหายส่วนแรก Zero Excess + ประกันการโจรกรรม (SCDW + TP)',
      insurance_short: 'SCDW + TP',
      cover_type: ['3th', 'zero', 'thief'],
      price: '265-588 บาท'
    },
    {
      insurance: 'ประกันแบบไม่มีค่าเสียหายส่วนแรกเต็มรูปแบบ + ประกันการโจรกรรม (PCDW + TP)',
      insurance_short: 'PCDW + TP',
      cover_type: ['3th', 'zero', 'thief', 'wheel'],
      price: '350-650 บาท'
    }
  ],
  en: [
    {
      insurance: 'Basic Insurance with Excess (CDW)',
      insurance_short: 'CDW',
      cover_type: ['3th'],
      price: 'Included in the rental price'
    },
    {
      insurance: 'Zero Excess Insurance (SCDW/SLDW/ECDW)',
      insurance_short: 'SCDW/SLDW/ECDW',
      cover_type: ['3th', 'zero'],
      price: '240-535 baht'
    },
    {
      insurance: 'Comprehensive Zero Excess Insurance (PCDW/PLDW)',
      insurance_short: 'PCDW/PLDW',
      cover_type: ['3th', 'zero', 'wheel'],
      price: '350-550 baht'
    },
    {
      insurance: 'Theft Protection Insurance (TP)',
      insurance_short: 'TP',
      cover_type: ['thief'],
      price: '85-150 baht'
    },
    {
      insurance: 'Zero Excess + Theft Protection Insurance (SCDW + TP)',
      insurance_short: 'SCDW + TP',
      cover_type: ['3th', 'zero', 'thief'],
      price: '265-588 baht'
    },
    {
      insurance: 'Comprehensive Zero Excess + Theft Protection Insurance (PCDW + TP)',
      insurance_short: 'PCDW + TP',
      cover_type: ['3th', 'zero', 'thief', 'wheel'],
      price: '350-650 baht'
    }
  ]
};
const InterInsuranceCompareTable: FunctionComponent<DefaultProps> = ({ prefix }) => {
  const header: headerType = tableHeader[prefix];
  const content: insuranceType[] = insurances[prefix];

  return (
    <div className="credit-insurance">
      <Table striped bordered className="insurance-compare-table">
        <thead>
          <tr>
            <th>{header.title}</th>
            {cover.concat(header.child).map((h, i) => (
              <th key={i}>
                <img src={h.src} />
                {h[`element_${prefix}`]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {content.map((r, contentIndex) => {
            return (
              <tr key={contentIndex}>
                <td>{r.insurance}</td>
                {coverType.map((type, typeIndex) => (
                  <td key={typeIndex}>
                    {r.cover_type.includes(type) ? (
                      <img src="/assets/pages/help/insurance/green-check-icon.svg" />
                    ) : (
                      '-'
                    )}
                  </td>
                ))}
                <td className={`price ${contentIndex === 0 ? 'green' : ''}`}>{r.price}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="insurance-compare-table--mobile">
        {content.map((insurance) => {
          return (
            <Card key={insurance.insurance_short} className="card--insurance mb-2 shadow">
              <Card.Header className="border-0 bg-transparent">
                <div>
                  <p>ชื่อประกัน</p>
                  <p>
                    <strong>{insurance.insurance_short}</strong>
                  </p>
                </div>
                <div>
                  <p className="text-right">ราคาต่อวัน*</p>
                  <p className="text-right">
                    <strong>{insurance.insurance_short === 'CDW' ? 'ฟรี' : insurance.price}</strong>
                  </p>
                </div>
              </Card.Header>
              <Card.Body>
                <p>ความคุ้มครอง</p>
                <div className="cover-box">
                  {cover
                    .filter((coverItem) => {
                      return insurance.cover_type.includes(coverItem.name);
                    })
                    .map((coverItem, i) => (
                      <div key={i} className="cover-box__item">
                        <img src="/assets/pages/help/insurance/green-check-icon.svg" className="py-2" />
                        <div className="cover-detail py-2">
                          <img src={coverItem.src} />
                          <span>{coverItem.short_element}</span>
                        </div>
                      </div>
                    ))}
                </div>
              </Card.Body>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default InterInsuranceCompareTable;
