import { sendToDataLayer } from '@utils';

const INSURANCE_EVENT_CATEGORY = 'content_insurance_section';

export const trackInsurancePaymentType = (paymentType: string) =>
  sendToDataLayer(undefined, {
    event_category: INSURANCE_EVENT_CATEGORY,
    event_action: 'payment_type',
    event_label: `${paymentType}`
  });
