import { FunctionComponent } from 'react';
import HelpTabs from '@pages/help/components/tabs';
import Table from 'react-bootstrap/Table';

import InterInsuranceCompareTable from './insurance-table';
import { trackInsurancePaymentType } from './tracking';
import './insurance.scss';
import { DefaultProps } from '../interface';

const interContent = {
  th: [
    'คำอธิบาย : ประกันความคุ้มครองและสิทธิประโยชน์',
    'หมายเหตุ: ชื่อและเงื่อนไขของประกันอาจมีการเปลี่ยนแปลงไปตามที่บริษัทรถเช่ากำหนด',
    {
      'ประกันพื้นฐาน มีค่าเสียหายส่วนแรก (CDW)':
        'Collision Damage Waiver :CDW กรณีเกิดอุบัติเหตุระหว่างเช่าโดยที่ผู้เช่ารถเป็นฝ่ายผิดหรือไม่มีคู่กรณี หรือรถมีรอยขีดข่วนจากการขับขี่ ผู้เช่าต้องรับผิดชอบค่าเสียหายที่เกิดขึ้นไม่เกินค่าเสียหายส่วนแรกที่ทางบริษัทรถเช่ากำหนด การคุ้มครองนี้ครอบคลุมเพียงตัวรถและไม่ครอบคลุมส่วนอื่นๆ ของรถ (เช่น กระจก ยาง ภายใน หลังคา หรือช่วงล่างของรถ) หรือค่าปรับหรือค่าใช้จ่ายต่างๆ ที่อาจเกิดขึ้นระหว่างการเช่า หรืออุปกรณ์ใดๆ ที่มีอยู่ในรถ (เช่น เก้าอี้เด็ก อุปกรณ์ GPS หรือทรัพย์สินส่วนบุคคลอื่นๆ เป็นต้น) ทั้งนี้ การคุ้มครองนี้มีผลก็ต่อเมื่อท่านปฏิบัติตามข้อตกลงของการเช่าเท่านั้น'
    },
    {
      'ประกันภัยบุคคลที่ 3 (TPL)':
        'Third Party Liability :TPL ในกรณีที่เกิดความเสียหายทางร่างกายและทรัพย์สินของบุคคลที่ 3 อันเกิดจากความผิดของผู้ขับขี่ระหว่างการเช่านั้น ประกันภัยบุคคลที่ 3 (TPL) จะคุ้มครองถึงความรับผิดดังกล่าวต่อร่างกายและทรัพย์สินตามนโยบาย ทั้งนี้ ความคุ้มครองไม่รวมถึงความเสียหายใดทั้งทางร่างกายและทรัพย์สินของผู้เช่าเอง หรือต่อรถที่เช่า และการคุ้มครองนี้มีผลต่อเมื่อท่านปฏิบัติตามข้อตกลงการเช่าเท่านั้น'
    },
    { 'กรณีเลือกซื้อประกันความคุ้มครองของผู้เช่ารถ (เพิ่มเติม)': '' },
    {
      'ประกันแบบไม่มีค่าเสียหายส่วนแรก Zero Excess (SCDW, SLDW, ECDW)':
        'Super Collision Damage Waiver: SCDW, Super Loss Damage Waive: SLDW, Extra Collision Damage Waiver: ECDWกรณีเกิดอุบัติเหตุระหว่างเช่า ผู้เช่าไม่ต้องรับผิดชอบค่าเสียหายที่เกิดขึ้น การคุ้มครองนี้ครอบคลุมเพียงตัวรถ และไม่ครอบคลุมส่วนอื่นๆ ของรถ (เช่น กระจก ยาง ฝาครอบล้อ กระทะล้อ ภายใน หลังคา หรือช่วงล่างของรถ) หรือค่าปรับหรือค่าใช้จ่ายต่างๆ ที่อาจเกิดขึ้นระหว่างการเช่า หรืออุปกรณ์ใดๆ ที่มีอยู่ในรถ (เช่น เก้าอี้เด็ก อุปกรณ์ GPS อุปกรณ์เสริมกุญแจ เอกสารสำคัญของรถยนต์หาย หรือทรัพย์สินส่วนบุคคลอื่นๆ เป็นต้น) ทั้งนี้ การคุ้มครองนี้มีผลก็ต่อเมื่อท่านปฏิบัติตามข้อตกลงของการเช่าเท่านั้น'
    },
    {
      'ประกันแบบไม่มีค่าเสียหายส่วนแรกเต็มรูปแบบ (PCDW, PLDW)':
        'Premium Collision Damage Waiver: PCDW, Premium Loss Damage Waive: PLDWกรณีเกิดอุบัติเหตุระหว่างเช่า ผู้เช่าไม่ต้องรับผิดชอบค่าเสียหายที่เกิดขึ้น การคุ้มครองนี้ครอบคลุมเพียงตัวรถ กระจก ยาง และไม่ครอบคลุมส่วนอื่นๆ ของรถ (เช่น ภายใน หลังคา หรือช่วงล่างของรถ) หรือค่าปรับหรือค่าใช้จ่ายต่างๆ ที่อาจเกิดขึ้นระหว่างการเช่า หรืออุปกรณ์ใดๆ ที่มีอยู่ในรถ (เช่น เก้าอี้เด็ก อุปกรณ์ GPS หรือทรัพย์สินส่วนบุคคลอื่นๆ เป็นต้น) ทั้งนี้ การคุ้มครองนี้มีผลก็ต่อเมื่อท่านปฏิบัติตามข้อตกลงของการเช่าเท่านั้น'
    },
    {
      'ประกันการโจรกรรม (TP)':
        'Theft Protection: TP หากรถยนต์ที่เช่าถูกขโมย ประกันภัยประเภทนี้จะครอบคลุมในส่วนที่ผู้เช่าจะต้องจ่ายชดเชยในส่วนที่สูญเสียไป ในกรณีที่รถถูกโจรกรรม ประกันจะคุ้มครองให้กับผู้เช่ารถ ซึ่งความรับผิดชอบจากรถกรณีรถหายจะเป็น 0 บาท ทั้งนี้ การคุ้มครองนี้มีผลก็ต่อเมื่อท่านปฏิบัติตามข้อตกลงของการเช่าเท่านั้น'
    },
    {
      'ประกันภัยอุบัติเหตุส่วนบุคคล (PAI)':
        'Personal Accident Insurance: PAI ในกรณีที่เกิดอุบัติเหตุประกันภัยประเภทนี้ครอบคลุมค่าใช้จ่ายทางการแพทย์ และคุ้มครองตามกฎหมายในกรณีบาดเจ็บ โดยครอบคลุมถึงการเสียชีวิตและการสูญเสียแขน ขา ความคุ้มครองนี้ใช้ได้เฉพาะในกรณีที่ผู้ขับขี่ปฏิบัติตามเงื่อนไขของสัญญาเช่าเท่านั้น'
    }
  ],
  en: [
    'Explanation: Coverage and Benefits',
    `Note: Names and conditions of coverage may vary according to the car rental company's policies.`,
    {
      'Basic Insurance with Excess (CDW)':
        'Collision Damage Waiver (CDW) covers damages incurred during the rental period when the renter is at fault, there is no third party involved, or there are scratches caused by driving. The renter is liable for damages up to the amount of the excess as set by the rental company. This coverage encompasses only the vehicle itself and does not include other parts of the vehicle (such as windows, tires, interior, roof, or undercarriage) or any fines or charges that may arise during the rental. It also excludes any accessories present in the vehicle (e.g., child seats, GPS devices, or personal belongings). This coverage is effective only when the renter complies with the rental terms and conditions.'
    },
    {
      'Third Party Liability Insurance (TPL)':
        'Third Party Liability (TPL) coverage provides protection against bodily injury and property damage to third parties resulting from the negligence of the renter during the rental period. TPL insurance will cover the aforementioned liabilities according to the policy. However, this coverage does not extend to any injuries or property damage suffered by the renter themselves or to the rented vehicle. This coverage is effective only when the renter complies with the rental terms and conditions.'
    },
    { 'Additional Insurance Plan Coverage:': '' },
    {
      'Super Collision Damage Waiver: SCDW, Super Loss Damage Waiver: SLDW, Extra Collision Damage Waiver: ECDW':
        'In the event of an accident during the rental period, where the renter is at fault, has no counterparty, or the vehicle has scratches from driving, the renter is not liable for the damages incurred. This coverage only extends to the rented vehicle and does not include other parts of the vehicle (such as windows, tires, wheel rims, hubcaps, interior, roof, or undercarriage) or any fines or expenses that may arise during the rental period, or any items present in the vehicle (such as child seats, GPS devices, spare keys, vehicle documents lost, or other personal belongings). This coverage is effective only when the renter complies with the rental terms and conditions.'
    },
    {
      'Premium Collision Damage Waiver: PCDW, Premium Loss Damage Waiver: PLDW':
        'In the event of an accident during the rental period, the renter is not liable for the damages incurred. This coverage extends to the rented vehicle, windows, and tires, and does not cover other parts of the vehicle (such as interior, roof, or undercarriage). It also excludes any fines or expenses that may arise during the rental period, or any items present in the vehicle (such as child seats, GPS devices, spare keys, vehicle documents lost, or other personal belongings). This coverage is effective only when the renter complies with the rental terms and conditions.'
    },
    {
      'Theft Protection: TP': `Theft Protection (TP) covers the renter in the event of the rental vehicle being stolen. This insurance covers the portion that the renter would be liable to compensate for the loss. In the case of vehicle theft, the liability from the renter's side would be 0. This coverage is effective only when the renter complies with the rental terms and conditions.`
    },
    {
      'Personal Accident Insurance: PAI': `Personal Accident Insurance (PAI) covers medical expenses and provides compensation in case of injury, including death or loss of limbs, resulting from an accident. This coverage is applicable only when the driver adheres to the rental agreement's terms and conditions.`
    }
  ]
};
export const content = {
  th: [
    'รถทุกคันในระบบของ Drivehub มีประกันภัยชั้น 1 ขั้นพื้นฐาน (ซึ่งรวมอยู่ในค่าเช่าแล้ว) แต่จะไม่รวมค่าเสียหายส่วนแรกในกรณีที่ลูกค้าเกิดอุบัติเหตุโดยท่านเป็นฝ่ายผิดหรือไม่มีคู่กรณี',
    'หากเกิดอุบัติเหตุและท่านไม่ซื้อประกันเพิ่มเติมกับบริษัทรถเช่า โดยท่านเป็นฝ่ายผิดหรือไม่มีคู่กรณีบริษัทรถเช่าจะเรียกเก็บค่าเสียหาย(“ค่าเสียหายส่วนแรก”) ระหว่าง 1,000 - 50,000 บาท ในอัตราอ้างอิงตามประเภทรถดังนี้',
    <>
      หากบริษัทประกันภัยประเมินราคาแล้ว ได้ความว่า{' '}
      <b>
        ค่าเสียหายเกินกว่าวงเงินคุ้มครองที่กำหนดไว้
        ผู้เช่า/ผู้ยืมตกลงรับผิดชอบชดใช้เงินค่าใช้จ่ายส่วนเกินที่บริษัทประกันภัยไม่ได้คุ้มครอง
      </b>
    </>,
    'คำอธิบาย : ประกันความคุ้มครองและสิทธิประโยชน์',
    'ประกันภัยชั้น 1 ขั้นพื้นฐาน (Collision Damage Waiver :CDW มีค่าเสียหายส่วนแรก)',
    'กรณีเกิดอุบัติเหตุระหว่างเช่าโดยที่ผู้เช่ารถเป็นฝ่ายผิดหรือไม่มีคู่กรณี หรือรถมีรอยขีดข่วนจากการขับขี่ ผู้เช่าต้องรับผิดชอบค่าเสียหายที่เกิดขึ้นไม่เกินค่าเสียหายส่วนแรกที่ทางบริษัทรถเช่ากำหนด',
    'ประกันแบบไม่มีค่าเสียหายส่วนแรก Zero Excess (เฉพาะบริษัทรถเช่า True Leasing และ Indy Car Rental)',
    'กรณีเกิดอุบัติเหตุระหว่างเช่า ผู้เช่าไม่ต้องรับผิดชอบค่าเสียหายที่เกิดขึ้น',
    'หมายเหตุ',
    'การคุ้มครองนี้ครอบคลุมเพียงตัวรถ ไม่ครอบคลุมถึงส่วนอื่นๆ ของรถ',
    'หรืออุปกรณ์ใดๆ ที่มีอยู่ในรถ',
    'และไม่รวมถึงค่าปรับ ค่าใช้จ่ายต่างๆ ที่อาจเกิดขึ้นระหว่างการเช่า ทั้งนี้ การคุ้มครองจะมีผลก็ต่อเมื่อผู้เช่าปฏิบัติตามข้อตกลงของการเช่าเท่านั้น',
    'เช่น กระจก ยาง ฝาครอบล้อ กระทะล้อ ภายใน หลังคา หรือช่วงล่างของรถ',
    'เช่น เก้าอี้เด็ก อุปกรณ์ GPS อุปกรณ์เสริมกุญแจ เอกสารสำคัญของรถ หรือทรัพย์สินส่วนบุคคลอื่นๆ'
  ],
  en: [
    'Every car comes with basic Level 1 insurance coverage (included in the rental fee), but it does not include the deductible in case of an accident where you are at fault or there is no counterparty.',
    'If an accident occurs and you do not purchase additional insurance from the rental company, and you are at fault or there is no counterparty, the rental company will charge a deductible ("deductible cost") ranging from 1,000 - 50,000 Baht, depending on the vehicle type.',
    <>
      If the insurance company assesses and determines that{' '}
      <b>
        the cost of damages exceeds the specified coverage limit, the renter agrees to be responsible for covering the
        excess expenses that are not covered by the insurance company.
      </b>
    </>,
    'Explanation: Coverage Insurance and Benefits',
    'Basic Level 1 Insurance Coverage (Collision Damage Waiver: CDW with Excess):',
    'In the event of an accident during the rental period where the renter is at fault, has no counterpart, or the vehicle exhibits signs of wear and tear from driving, the renter is responsible for covering the incurred damage up to the predetermined excess amount set by the rental company.',
    'Zero Excess Insurance Option (Exclusive to True Leasing and Indy Car Rental):',
    'In the event of an accident during the rental period, the renter is not required to cover the incurred damage. This option is available only for True Leasing and Indy Car Rental.',
    'Note',
    'This coverage only extends to the vehicle itself and does not encompass other parts of the vehicle',
    'or any equipment present within it ',
    `Additionally, it does not include fines or various expenses that may arise during the rental period. It's important to note that the coverage only takes effect when the renter adheres to the rental terms and conditions.`,
    `For example, mirrors, tires, wheel covers, hubcaps, interior, roof, or the vehicle's undercarriage.`,
    `For example, child seats, GPS devices, key accessories, important vehicle documents, or other personal belongings.`
  ]
};

export const tableContent = {
  th: [
    'ประเภทรถ',
    'ตัวอย่าง',
    'ค่าเสียหายส่วนแรกสูงสุดไม่เกิน (บาท)',
    'รถอีโคร์คาร์ และ รถขนาดเล็ก',
    'March / Almera / Yaris / Vios / City / Jazz / Ciaz',
    '30,000',
    'รถขนาดกลาง',
    'Altis / Civic / CHR / HRV / Slyphy / Juke',
    '40,000',
    'รถขนาดใหญ่ และ รถเอสยูวี',
    'Camry / Accord / Fortuner / CRV / Pajero / Teana',
    '50,000'
  ],
  en: [
    'Types of Vehicles',
    'Car Models',
    'Maximum Deductible Amount (in Baht) for Damages',
    'Economy car and Small car',
    'March / Almera / Yaris / Vios / City / Jazz / Ciaz',
    '30,000',
    'Mid-Size Car',
    'Altis / Civic / CHR / HRV / Slyphy / Juke',
    '40,000',
    'Full-Size Car and SUV',
    'Camry / Accord / Fortuner / CRV / Pajero / Teana',
    '50,000'
  ]
};
const InsuranceSection: FunctionComponent<DefaultProps> = ({ prefix }) => {
  const text = content[prefix];
  const tableText = tableContent[prefix];
  const contentInter: { [key: string]: string }[] & string[] = interContent[prefix];

  return (
    <div className="insurance">
      <HelpTabs prefix={prefix} onExpand={(paymentType) => trackInsurancePaymentType(paymentType)}>
        {({ LocalTab, InterTab }) => {
          return (
            <>
              <LocalTab>
                <div className="cash pt-4">
                  <p className="mb-2">{text[0]}</p>
                  <p className="mb-2">{text[1]}</p>
                  <Table striped bordered>
                    <tbody>
                      <tr>
                        <th>{tableText[0]}</th>
                        <th className="text-center">{tableText[1]}</th>
                        <th className="text-center">{tableText[2]}</th>
                      </tr>
                      <tr>
                        <td>{tableText[3]}</td>
                        <td className="text-center">{tableText[4]}</td>
                        <td className="text-center">{tableText[5]}</td>
                      </tr>
                      <tr>
                        <td>{tableText[6]}</td>
                        <td className="text-center">{tableText[7]}</td>
                        <td className="text-center">{tableText[8]}</td>
                      </tr>
                      <tr>
                        <td>{tableText[9]}</td>
                        <td className="text-center">{tableText[10]}</td>
                        <td className="text-center">{tableText[11]}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <br />
                  {text[2]}
                  <div className="zero-excess-local-box">
                    <p className="title">{text[3]}</p>
                    <br />
                    <p className="title">{text[4]}</p>
                    <p>{text[5]}</p>
                    <p className="title">{text[6]}</p>
                    <p>{text[7]}</p>
                    <br />
                    <p className="title">{text[8]}</p>
                    {text[9]}
                    <sup>1</sup> {text[10]}
                    <sup>2</sup> {text[11]}
                    <p className="mt-2">
                      <sup>1</sup>
                      {text[12]}
                    </p>
                    <p>
                      <sup>2</sup>
                      {text[13]}
                    </p>
                  </div>
                </div>
              </LocalTab>
              <InterTab>
                <div className="credit pt-4">
                  <InterInsuranceCompareTable prefix={prefix} />
                  <div className="credit-description">
                    <h2>{contentInter[0]}</h2>
                    {contentInter.slice(2).map((item, index) => {
                      const title = Object.keys(item);
                      const value = Object.values(item);

                      return (
                        <div key={index}>
                          <h3>{title}</h3>
                          <p className="mb-2">{value}</p>
                          {contentInter.slice(2).length !== index + 1 && !!value[0] && <br />}
                        </div>
                      );
                    })}
                    <br />
                    <h3>{contentInter[1]}</h3>
                  </div>
                </div>
              </InterTab>
            </>
          );
        }}
      </HelpTabs>
    </div>
  );
};

export default InsuranceSection;
